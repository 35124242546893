<template>
  <section>
    <div class="container overflow-hidden">
      <div class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pb-5
                ps-0
                pe-0
                mx-auto
                mb-4
              ">
        <div class="partnership-banner-items-container">
          <div class="row align-items-center partnership-banner-item">
            <div class="
                      col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6
                    ">
              <p class="h2 text-main  partner-corparative-text">
                Agentlər üçün
              </p>
              <p class="partner-corparative-content">
                Qoşulun və bir sıra şirkətlərin
                məhsullarının satışı üçün sadə,
                sürətli və effektiv bir həll əldə edin.
              </p>
              <div class="partner-corporative-buttons">
                <a class="
                          btn-main
                          btn-default
                          text-decoration-none
                          btn-hover
                          d-inline-block
                          font-size-default
                          cursor-pointer
                        " @click.prevent="goToForm">
                  İndi başla
                </a>
                <router-link to="/contact" class="
                          btn-default
                          text-decoration-none
                          btn-hover
                          d-inline-block
                          font-size-default
                        ">
                  Bizimlə əlaqə
                </router-link>
              </div>
            </div>
            <div class="
                      col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6
                    ">
              <img src="@/assets/images/partner-agents.png" class="mx-auto partner_corp" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="corporate-services">
    <div class="container overflow-hidden">
      <div class="
              col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
              pb-5
              pl-0
              pr-0
              mx-auto
              mb-4
              mt-2
              corporate-services-container
            ">
        <p class="h3 about-text">
          Bizimlə <span class="text-blue">əməkdaşlıq</span> sizə nə verəcək:
        </p>
        <div class="
                  corporate-services-items-container
                  rounded
                  py-4
                  text-start
                ">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Şəxsi kabinet və öz agent şəbəkənizi qurmaq imkanı;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Satılan sığorta məhsulları barədə bütün məlumatların bir kabinetdə görmək imkanı;
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Satışların rahat idarə edilməsi hesabına satışların 2 dəfə artırmaq imkanı;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Satılmış sığorta şəhadətnamələrin vaxtları bitdikcə rahat yenilənmə aləti;
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Müxtəlif sığorta şirkət məhsulların müştərilərinizə 24/7 rejimində satılması üçün unikal texnoloji həll;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Rahat hesablaşma formaları.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="partners-what-to-do">
      <div class="container overflow-hidden position-relative">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
            <p class="h2 text-main fw-bolder text-center pt-2">
              Əməkdaşlığa başlamaq <br />üçün nə etməli?
            </p>
          </div>
          <div class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                  pb-5
                  mt-5
                  mx-auto
                ">
            <div class="row position-relative">
              <img src="@/assets/images/partner-way.svg" class="agent-ways-bg" />
              <div class="corporative-solution-row mt-5">
                <div class="
                      col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                      corporative-solution-item
                    ">
                  <div class="text-center mx-auto d-flex align-items-center">
                    <img src="@/assets/images/agent-query.svg" class="mx-auto" />
                  </div>
                  <p class="text-center pt-2">
                    Əməkdaşlıq üçün müraciət göndərmək
                  </p>
                </div>
                <div class="
                      col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                      corporative-solution-item
                    ">
                  <div class="text-center    mx-auto d-flex align-items-center">
                    <img src="@/assets/images/agent-pen.svg" class="mx-auto" />
                  </div>
                  <p class="text-center pt-2">
                    Başlamaq üçün
                    müqaviləni bağlamaq
                  </p>
                </div>
                <div class="
                      col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                      corporative-solution-item
                    ">
                  <div class="text-center mx-auto d-flex align-items-center">
                    <img src="@/assets/images/agent-puzzle.svg" class="mx-auto" />
                  </div>
                  <p class="text-center pt-2">
                    Şəxsi kabinetə
                    girişlər əldə etmək
                  </p>
                </div>
                <div class="
                      col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                      corporative-solution-item
                    ">
                  <div class="text-center    mx-auto d-flex align-items-center">
                    <img src="@/assets/images/agent-money_bag.svg" class="mx-auto" />
                  </div>
                  <p class="text-center  pt-2">
                    Təklif olunan
                    xidmətdən faydalanmaq
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <request-form />
</template>

<script>
import RequestForm from "./RequestForm.vue";

export default {
  components: { RequestForm },
  methods: {
    goToForm() {
      document.getElementById("partnership-form-container").scrollIntoView();
    },
  },
};
</script>